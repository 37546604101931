export const siteConfig = {
    // Basic site info
    name: "Da Wae",
    title: "Da Wae",
    description: "Nevah stop clicking! We must find da wae!",

    // Add content section for reusable text
    content: {
        hero: {
            title: "Do You Know Da Wae?",
            subtitle: "Nevah stop clicking! We must find da wae!",
            buttonText: "Find Da Wae"
        },
        about: {
            title: "About Da Wae",
            description: "Nevah stop clicking! We must find da wae!"
        },
        wiki: {
            url: "https://weykipedia.fandom.com/wiki/Special:AllPages",
            buttonText: "Visit Da Wiki"
        },
        memes: {
            title: "Ugandan Gallery",
            description: "Ugandan memes"
        },
        footer: {
            copyright: "© 2024 Da Wae. All Ebola reserved.",
            description: "Nevah stop clicking! We must find da wae!"
        }
    },

    // Contract info
    contract: {
        address: "DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ",
        description: "DO YOU KNOW $DAWAE?\nBruddas $DAWAE is DA WAE\n\nJoin Uganda army Brudda and cum click and spit wit us\n\nYou must help us find DA QWEEN Bruddas"
    },

    // Social links
    socials: {
        telegram: "https://t.me/dawaectoportal",
        twitter: "https://x.com/Solana_Dawae",
        twitter_community: "https://x.com/i/communities/1847151894243725699",
        TikTok: "https://tiktok.com/@dawae_ug",
    },

    // Trading links
    trading: {
        Jupiter: "https://jup.ag/swap/SOL-DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ",
        Chart: "https://www.dextools.io/app/en/solana/pair-explorer/q3ULfxUXUuqU8U2YrWC3ajRRJaURvdrHX1AHdDreCnn",
        BullX: "https://bullx.io/terminal?chainId=1399811149&address=DZSs9nHSr9BBunLNWd6PDstesJ4PBLMFVK1GbZ9urYNZ&r=BYMIJHPASX",
        Photon: "https://photon-sol.tinyastro.io/en/lp/q3ULfxUXUuqU8U2YrWC3ajRRJaURvdrHX1AHdDreCnn",
    },

    // Navigation links
    navigation: [
        { name: "Home", href: "#home" },
        { name: "About", href: "#about" },
        { name: "Buy now", href: "#buy" },
        { name: "Memes", href: "#memes" },
        { name: "Community", href: "#community" }
    ],

    // Assets paths
    assets: {
        logo: "/ugandan-knuckles.png",
        introVideo: {
            src: "/videos/ugandan-intro.MP4",
            type: "video/mp4",
            poster: "/images/intro-thumbnail.png"
        }
    }
}

const { socials, trading } = siteConfig
export const socialsLinks = [
    {
        title: "Telegram",
        icon: "FaTelegram",
        url: socials.telegram,
    },
    {
        title: "Twitter",
        icon: "FaTwitter",
        url: socials.twitter,
    },
    {
        title: "TikTok",
        icon: "tiktok",
        url: socials.TikTok,
    },
    {
        title: "Community",
        icon: "FaUsers",
        url: socials.twitter_community,
    }
]


export const tradingLinks = [
    {
        title: "Buy Now",
        icon: "jupiter",
        url: trading.Jupiter,
    },
    {
        title: "Chart",
        icon: "dextools",
        url: trading.Chart,
    },
    {
        title: "BullX",
        icon: "bullx",
        url: trading.BullX,
    },
    {
        title: "Photon",
        icon: "photon",
        url: trading.Photon,
    }
]
