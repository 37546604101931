import * as Icons from 'react-icons/fa'
import { socialsLinks, tradingLinks as tradingLinksConfig } from '../config/site.config'
import Icon from './Icon'

export default function UsefulLinks() {
    const communityLinks = socialsLinks.map((link, index) => ({
        ...link,
        bgColor: 'bg-ugandan-white'
    }))

    const tradingLinks = tradingLinksConfig.map((link, index) => ({
        ...link,
        bgColor: 'bg-ugandan-white'
    }))

    const renderLinks = (links) => {
        return links.map((link) => {
            let IconComponent
            if (link.icon.startsWith('Fa')) {
                IconComponent = Icons[link.icon]
                return (
                    <a
                        key={link.title}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${link.bgColor} hover:opacity-90 transition rounded-lg p-6 text-ugandan-black text-center`}
                    >
                        <div className="flex flex-col items-center space-y-3">
                            {IconComponent && (
                                <div className="w-8 h-8 flex items-center justify-center">
                                    <IconComponent className="w-full h-full text-ugandan-black" />
                                </div>
                            )}
                            <span className="font-medium">{link.title}</span>
                        </div>
                    </a>
                )
            } else {
                return (
                    <a
                        key={link.title}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${link.bgColor} hover:opacity-90 transition rounded-lg p-6 text-ugandan-black text-center`}
                    >
                        <div className="flex flex-col items-center space-y-3">
                            <div className="w-8 h-8 flex items-center justify-center">
                                <Icon name={link.icon} className="w-full h-full" />
                            </div>
                            <span className="font-medium">{link.title}</span>
                        </div>
                    </a>
                )
            }
        })
    }

    return (
        <section id="community" className="py-16 bg-ugandan-black">
            <div className="container mx-auto px-4 gap">
                <h2 className="text-3xl font-bold text-center mb-12 text-ugandan-white">Community Links</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    {renderLinks(communityLinks)}
                </div>

                <h2 id="buy" className="text-3xl font-bold text-center mb-12 text-ugandan-white mt-10">Trading Links</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    {renderLinks(tradingLinks)}
                </div>
            </div>
        </section>
    )
}