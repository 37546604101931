import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function MemeCarousel() {
    const [memes, setMemes] = useState([])

    const loadMemes = async () => {
        try {
            const memeContext = import.meta.glob('/public/memes/*.{jpg,jpeg,png,gif,webp,avif}')
            const memeFiles = Object.keys(memeContext).map(path =>
                path.replace('/public', '')
            )

            // Randomly select 10 memes
            const shuffledMemes = [...memeFiles]
                .sort(() => Math.random() - 0.5)
                .slice(0, 10)

            setMemes(shuffledMemes)
        } catch (error) {
            console.error('Error loading memes:', error)
        }
    }

    useEffect(() => {
        loadMemes()
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        adaptiveHeight: false,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    }

    if (memes.length === 0) {
        return null
    }

    return (
        <section id="memes" className="py-16 bg-ugandan-black">
            <div className="container mx-auto px-4">
                <div className="flex justify-center items-center mb-8 relative">
                    <div className="flex items-center gap-3">
                        <h2 className="text-3xl font-bold text-ugandan-white">
                            Meme Gallery
                        </h2>
                        <button
                            onClick={loadMemes}
                            className="text-ugandan-white hover:text-ugandan-red/80 transition-colors"
                            aria-label="Refresh memes"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="max-w-3xl mx-auto relative">
                    <div className="carousel-container">
                        <Slider {...settings}>
                            {memes.map((meme, index) => (
                                <div key={index} className="px-2">
                                    <div className="relative pt-[56.25%]">
                                        <img
                                            src={meme}
                                            alt={`Meme ${index + 1}`}
                                            className="absolute inset-0 w-full h-full object-contain bg-ugandan-black rounded-lg"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}